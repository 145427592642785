

.aximo-social-icon{
  ul{
    li{
      margin: 0 3px;
      display: inline-block;
      &:first-child{
        margin-left: 0;
      }
      &:last-child{
        margin-right: 0;
      }
      a{
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        transition: all 0.4s;
        border: 1px solid var(--white-smoke);
        background-color: var(--dark-bg);
        i{
          transition: all 0.4s;
          color: var(--white-smoke);
        }
        &:hover i{
          color: var(--heading-color);
        }
        &:hover{
          background-color: var(--accent-bg);
          box-shadow: 0 2px 0 0 var(--white-smoke);
          border: 1px solid var(--dark-bg);
        }
        
        
      }
    }
  }
  &.social-large{
    ul{
      li{
        a{
          width: 45px;
          height: 45px;
          i{
            font-size: 22px;
          }
        }
      }
    }
  }

}

.aximo-social-icon2{
  ul{
    li{
      margin: 0 6px;
      display: inline-block;
      &:first-child{
        margin-left: 0;
      }
      &:last-child{
        margin-right: 0;
      }
      a{
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        transition: all 0.4s;
        background-color: var(--light-bg2);
        
        i{
          font-size: 24px;
            transition: all 0.4s;
            color: var(--heading-color2);
          }
        &:hover i{
          color: var(--orange-color);
        }
        
      }
    }
  }
  &.right{
    text-align: right;
    @include max(991px) {
      text-align: center;
      margin-bottom: 20px;
    }
  }
 
}

.header-social.aximo-social-icon {
  margin-right: 40px;
  @include max(1199px) {
    display: none;
  }
  ul{
    li{
      margin: 0 4px;
      a{
        width: 35px;
        height: 35px;
        background-color: transparent;
        border: 1px solid var(--heading-color);
        &:hover{
          background-color: var(--yellow-bg);
          border: 1px solid var(--yellow-bg);
        }
        i{
          color: var(--heading-color);
        }
      }
    }
  }
}

.aximo-social-icon3{
  ul{
    li{
      a{
        width: 35px;
        height: 35px;
        background-color: transparent;
        border: 1px solid var(--light-color3);
        &:hover{
          box-shadow: none;
          background-color: var(--orange-bg2);
        }
        svg{
          path{
            fill: var(--light-color3);
          }
        }
        &:hover svg path{
          fill: var(--light-color3);
        }
      }
    }
  }
}

.aximo-social-icon4{
  ul{
    li{
      a{
        width: 35px;
        height: 35px;
        background-color: #EBDECE;
        border: 1px solid #EBDECE;
        &:hover{
          box-shadow: none;
          background-color: var(--green-bg);
          border: 1px solid var(--green-bg);
        }
        i{
          color: var(--heading-color3);
        }
        &:hover i{
          color: var(--corn-color);
        }
      }
    }
  }
}