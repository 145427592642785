.aximo-list-icon{
  margin-top: 50px;
  ul{
    li{
      font-weight: 600;
      font-size: 21px;
      margin-bottom: 20px;
      position: relative;
      padding-left: 40px;
      text-align: left;
      color: var(--heading-color);
      @include max(991px) {
        font-size: 18px;
      }
      img{
        position: absolute;
        left: 0;
        top: 0;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}