.aximo-video{
  &-wrap{
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    border: 5px solid var(--heading-color);
  }
  &-popup{
    left: 50%;
    top: 50%;
    border-radius: 50px;
    position: absolute;
    transition: all 0.4s;
    transform: translate(-50%, -50%);
    &.play-btn1{
      box-shadow: 0 4px 0 0 var(--white-smoke);
      &:hover{
        box-shadow: none;
      }
    }
  }
}

.aximo-video{
  &-section{
    position: relative;
    img{
      border-radius: 5px;
      
     }
  }
  &-section2{
    position: relative;
    overflow: hidden;
    margin-top: -270px!important;
    @include max(1199px) {
      margin-top: -185px!important;
    }
    @include max(991px) {
      margin-top: -150px!important;
    }
    @include max(767px) {
      margin-top: -110px!important;
    }
    @include max(479px) {
      margin-top: -90px!important;
    }
    img{
      border-radius: 20px;
      @include max(767px) {
        border-radius: 10px;
      }
    }
    .waves{
      background-color: #000;
    }
  }
  
}


.play-btn-size{
  img{
    @include max(991px) {
      width: 90px;
      height: 90px;
    }
    @include max(767px) {
      width: 60px;
      height: 60px;
    }
  }
}

.waves {
  position: absolute;
  width: 185px;
  height: 185px;
  background: #000;
  opacity: 0;
  border-radius: 100%;
  left: -35px;
  top: -35px;
  z-index: 0;
  -webkit-animation: waves 3s ease-in-out infinite;
  animation: waves 3s ease-in-out infinite;
  z-index: -1;
  @include max(991px) {
    width: 160px;
    height: 160px;
  }
  @include max(767px) {
    width: 130px;
    height: 130px;
  }
  &.wave-1 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  &.wave-2 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  &.wave-3 {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }
}


@keyframes waves {
  0% {
      -webkit-transform: scale(0.2, 0.2);
      transform: scale(0.2, 0.2);
      opacity: 0;
  }

  50% {
      opacity: 0.9;
  }

  100% {
      -webkit-transform: scale(0.9, 0.9);
      transform: scale(0.9, 0.9);
      opacity: 0;
  }
}

.aximo-hero-video-section{
  @include max(991px) {
    margin-top: 50px;
  }
  @include max(767px) {
    margin-top: 30px;
 }
}
.aximo-video-popup3{
  position: relative;
  margin-left: 150px;
  @include max(1300px) {
    margin: 0;
  }
  
}

img.aximo-video-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
img.aximo-video-circle{
  -webkit-animation: rotate-animation 10s infinite linear;
  -moz-animation: rotate-animation 10s infinite linear;
  -o-animation: rotate-animation 10s infinite linear;
  animation: rotate-animation 10s infinite linear;
}

