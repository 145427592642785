a {
  text-decoration: none;
  @include rt-trs(0.24s);
}

img {
  max-width: 100%;
}

.rt-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mouse-cursor,
.pointer {
  cursor: pointer;
}




blockquote {
  font-weight: 700;
  position: relative;
  font-size: 24px;
  line-height: 30px;
  border-radius: 10px;
  padding: 30px;
  margin: 0 0 24px;
  color: var(--white-smoke);
  font-family: $heading-font;
  background-color: var(--dark-bg);
}
.border-transparent {
  border-color: transparent !important;
}
.bg-transsparent {
  background-color: transparent !important;
}
.hover\:bg-transsparent {
  &hover {
    background-color: transparent !important;
  }
}
.hover-shadow\:none {
  box-shadow: none !important;
}
.bgprefix-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.bgprefix-contain {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.bgprefix-full {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.position-parent {
  position: relative;
  z-index: 1;
}
.position-relative {
  position: relative;
}
.body-no-scrolling {
  overflow: hidden;
}
.img-fit {
  display: block;
  object-fit: cover;
  transform: translate(-50%, -50%);
  left: 50%;
  bottom: 0;
  right: 0;
  top: 50%;
  position: absolute;
  height: 100%;
  width: 100%;
}

.rt-mb-15 {
  margin-bottom: 15px;
}
.rt-mb-8{
  margin-bottom: 8px;
}
.rt-mb-2{
  margin-bottom: 2px;
}
.rt-mb-4{
  margin-bottom: 4px;
}
.rt-pt-15 {
  padding-top: 15px;
}
.rt-mb-12{
  margin-bottom: 12px;
}
.rt-spacer-15 {
  height: 15px;
}
.rt-mb-16{
  margin-bottom: 16px;
}
.rt-mb-25{
  margin-bottom: 25px;
}
.rt-mb-24{
  margin-bottom: 24px;
}
.rt-mb-28{
  margin-bottom: 28px;
}
.rt-mb-48{
  margin-bottom: 48px;
}
.rt-mb-32{
  margin-bottom: 32px;
}
.hr-0 {
  margin: 0;
  padding: 0;
 
}
.text-gray{
  color: #7B878C;
}
@each $color, $shades in $colors {
  @each $shade, $value in $shades {
    #{".text-" + $color + "-" + $shade} {
      color: #{$value} !important;
    }
    #{".bg-" + $color + "-" + $shade} {
      background-color: #{$value} !important;
    }

    #{".border-" + $color + "-" + $shade} {
      border-color: #{$value} !important;
    }

    #{".hoverbg-" + $color + "-" + $shade} {
      &:hover {
        background-color: #{$value} !important;
      }
    }

    #{".hover\\:" + "bg-" + $color + "-" + $shade} {
      &:hover {
        background-color: #{$value} !important;
      }
    }

    #{".hover\\:" + "text-" + $color + "-" + $shade} {
      &:hover {
        color: #{$value} !important;
      }
    }
    #{".hover\\:" + "border-" + $color + "-" + $shade} {
      &:hover {
        border-color: #{$value} !important;
      }
    }
  }
}

.hover\:border-transparent {
  border-color: transparent !important;
}

@for $i from 0 through 30 {
  .rt-mr-#{$i* 1} {
    margin-right: $i * 1px !important;
  }
  .rt-ml-#{$i* 1} {
    margin-left: $i * 1px !important;
  }
  .rt-rounded-#{$i* 1} {
    border-radius: $i * 1px !important;
  }
}

@for $i from 0 through 10 {
  .rt-mb-#{$i* 10} {
    margin-bottom: $i * 10px;
  }
  .rt-pt-#{$i* 10} {
    padding-top: $i * 10px;
  }
  .rt-spacer-#{$i* 10} {
    height: $i * 10px;
  }
}

@media (max-width: 1199.98px) {
  @for $i from 0 through 10 {
    .rt-mb-lg-#{$i* 10} {
      margin-bottom: $i * 10px;
    }
    .rt-pt-lg-#{$i* 10} {
      padding-top: $i * 10px;
    }
    .rt-spacer-lg-#{$i* 10} {
      height: $i * 10px;
    }
  }
}

@media (max-width: 991.98px) {
  @for $i from 0 through 10 {
    .rt-mb-md-#{$i* 10} {
      margin-bottom: $i * 10px;
    }
    .rt-pt-md-#{$i* 10} {
      padding-top: $i * 10px;
    }
    .rt-spacer-md-#{$i* 10} {
      height: $i * 10px;
    }
  }
}
@media (max-width: 767.98px) {
  @for $i from 0 through 10 {
    .rt-mb-xs-#{$i* 10} {
      margin-bottom: $i * 10px;
    }
    .rt-pt-xs-#{$i* 10} {
      padding-top: $i * 10px;
    }
    .rt-spacer-xs-#{$i* 10} {
      height: $i * 10px;
    }
  }
}

.pointer {
  cursor: pointer;
}
.text-hide{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
// slick slider gap

// .slick-slide {
//   margin-left: 24px;
// }

// .slick-list {
//   margin-left: -24px;
// }
// .slick-slider {
//   .single-item {
//     margin-bottom: 40px;
//   }
// }

.slick-bullet {
  .slick-dots li button:before {
    display: none !important;
  }
  .slick-dots {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      @include afterparent();
      width: 10px;
      display: inline-block;
      height: 10px;
      button {
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  &.deafult_style_dot {
    .slick-dots {
      li {
        button {
          overflow: hidden;
          transition: background 0.3s ease;
        }
        &.slick-active {
          width: 24px;
          button {
            border-radius: 32px;
          }
        }
      }
    }
  }
  &.dotstyle-fillup {
    .slick-dots {
      li {
        button {
          overflow: hidden;
          background-color: transparent;
          box-shadow: inset 0 0 0 2px #fff;
          transition: background 0.3s ease;
          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            height: 0;
            left: 0;
            width: 100%;
            background-color: #fff;
            box-shadow: 0 0 1px #fff;
            -webkit-transition: height 0.3s ease;
            transition: height 0.3s ease;
            border-radius: 50%;
          }
        }
        &.slick-active {
          button {
            &::after {
              height: 100%;
            }
          }
        }
      }
    }
  }
  &.dotstyle-scaleup {
    .slick-dots {
      li {
        button {
          overflow: hidden;
          background-color: rgba(#fff, 0.3);
          transition: all 0.3s ease;
        }
        &.slick-active {
          button {
            transform: scale(1.2);
            background-color: rgba(#fff, 1);
          }
        }
      }
    }
  }
  &.dotstyle-stroke {
    .slick-dots {
      li {
        button {
          transition: box-shadow 0.3s ease, background-color 0.3s ease;
          box-shadow: 0 0 0 2px rgba(#fff, 0);
        }
        &.slick-active {
          button {
            background-color: transparent;
            box-shadow: 0 0 0 2px #fff;
          }
        }
      }
    }
  }
  &.dotstyle-fillin {
    .slick-dots {
      li {
        button {
          background-color: transparent;
          box-shadow: inset 0 0 0 2px #fff;
          transition: box-shadow 0.3s ease;
        }
        &.slick-active {
          button {
            box-shadow: inset 0 0 0 8px #fff;
          }
        }
      }
    }
  }
  &.dotstyle-dotstroke {
    .slick-dots {
      li {
        box-shadow: 0px 0px 0px 2px rgba(#fff, 1);
        border-radius: 50%;
        transition: all 0.3s ease;
        button {
          transform: scale(0.4);
          background-color: #fff;
          transition: all 0.3s ease;
        }
        &.slick-active {
          button {
            transform: scale(1);
          }
        }
      }
    }
  }
  &.dotstyle-dotstroke2 {
    .slick-dots {
      li {
        box-shadow: 0px 0px 0px 2px rgba(#fff, 0);
        border-radius: 50%;
        transition: all 0.3s ease;
        button {
          background-color: #fff;
          transition: all 0.3s ease;
        }
        &.slick-active {
          box-shadow: 0px 0px 0px 2px rgba(#fff, 1);
          button {
            transform: scale(0.4);
          }
        }
      }
    }
  }
}

// scroll up css
#scrollUp {
  right: 30px;
  bottom: 30px;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  color: #fff;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  line-height: 55px;
  text-align: center;
}

.modal-header {
  padding: 20px 24px 20px 24px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0px;
  }
  
}
.modal-content {
  border-radius: 16px;
}
.modal-body {
  padding: 24px;
}



.dark-bg{
  background-color: var(--dark-bg);
}
.dark-bg2{
  background-color: var(--dark-bg2);
}
.green-bg{
  background-color: var(--green-bg);
}
.bg-light1{
  background-color: var(--light-bg1);
}
.bg-light2{
  background-color: var(--light-bg2);
}
.bg-light3{
  background-color: var(--light-bg3);
}
.bg-light4{
  background-color: var(--light-bg4);
}
.bg-light6{
  background-color: var(--light-bg6);
}
.bg-orange{
  background-color: var(--orange-bg);
}
.z-index{
  z-index: 0;
}