.aximo-service-wrap {
  border-bottom: 1px solid #E6E6E6;
  padding-bottom: 100px;
  @include max(991px) {
    padding-bottom: 70px;
  }
  @include max(767px) {
    padding-bottom: 50px;
  }
}

.aximo-iconbox{
  &-wrap{
    padding: 30px;
    border-radius: 20px;
    margin-bottom: 30px;
    transition: all 0.4s;
    border: 2px solid var(--heading-color);
    &:hover{
      background-color: var(--accent-bg);
      box-shadow: 4px 4px 0 0 var(--heading-color);
    }
  }
  &-icon{
    margin-bottom: 30px;
    i{
      font-size: 80px;
    }
  }
  &-data{
    h3{
      margin-bottom: 15px;
    }
  }
  // End iconbox one

  &-wrap2{
    padding: 24px;
    border-radius: 5px;
    margin-bottom: 30px;
    border: 2px solid var(--heading-color2);
  }
  &-icon2{
    margin-bottom: 30px;
    img{
      max-height: 174px;
    }
    &.bottom{
      margin-bottom: 0;
      margin-top: 30px;
    }
  }
  &-data2{
    h3{
      margin-bottom: 15px;
      font-family: $heading-font2;
      color: var(--heading-color2);
      
    }
  }
  // End iconbox2

  &-column{
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr;
    @include max(991px) {
      grid-template-columns: 1fr;
    }
  }

  &-wrap3{
    padding: 40px;
    border-radius: 20px;
    display: flex;
    @include max(767px) {
      padding: 30px;
    }
    &:nth-child(1){
      background-color: #8D7DFF;
    }
    &:nth-child(2){
      background-color: #FF7545;
    }
    &:nth-child(3){
      background-color: #FF94FF;
    }
    &:nth-child(4){
      background-color: #FFE757;
    }
  }
  &-icon3{
    margin-right: 24px;
    flex-shrink: 0;
  }
  &-data3{
    h3{
      font-size: 35px;
      margin-bottom: 20px;
      font-family: $heading-font3;
      @include max(1199px) {
        font-size: 30px;
      }
    }
    p{
      margin-bottom: 24px;
    }
  }



  // End three

  &-wrap4{
    padding: 24px;
    border-radius: 10px;
    margin-bottom: 30px;
    border: 2px solid var(--heading-color3);
    &:hover .aximo-icon path{
      stroke: var(--orange-color2);
    }
  }
  &-icon4{
    margin-bottom: 30px;
    i{
      font-size: 60px;
      color: var(--heading-color3);
    }
  }
  &-data4{
    h3{
      margin-bottom: 15px;
      line-height: 1.33em;
      font-family: $heading-font4;
      color: var(--heading-color3);
    }
    p{
      font-size: 20px;
      margin-bottom: 22px;
      color: var(--heading-color3);
    }
    .aximo-icon{
      path{
        transition: all 0.4s;
      
      }
      
      
    }
  }
  // end four

  
&-wrap5{
  display: flex;
  margin-bottom: 24px;
  &:last-child{
    margin-bottom: 0;
  }
}
&-icon5{
  flex-shrink: 0;
  margin-right: 15px;
  i{
    font-size: 40px;
    color: var(--heading-color);
  }
}
&-data5{
  h3{
    font-size: 29px;
    margin-bottom: 12px;
    @include max(767px) {
      font-size: 24px;
    }
  }
}
// end five

&-wrap6{
  margin-bottom: 30px;
}
&-icon6{
  margin-bottom: 30px;
}
&-data6{
  h3{
    margin-bottom: 12px;
    font-family: $heading-font5;
    @include max(767px) {
      font-size: 24px;
    }
  }
}


}

.aximo-numberbox{
  &-section{
    padding: 100px 0;
    @include max(991px){
      padding: 100px 0 70px;
    }
    @include max(767px){
      padding: 80px 0 50px;
    }
  }
  &-wrap{
    padding-top: 40px;
    display: flex;
    margin-bottom: 30px;
    transition: all 0.4s;
    border-top: 2px solid #E6E6E6;
    &:hover{
      border-top: 2px solid var(--orange-bg2);
    }
    &:hover .aximo-numberbox-number{
      color: var(--light-color3);
      border: 1px solid var(--orange-bg2);
      background-color: var(--orange-bg2);
    }
  }
  &-number{
    line-height: 1;
    font-size: 24px;
    font-weight: 600;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 24px;
    transition: all 0.4s;
    color: var(--heading-color3);
    border: 1px solid var(--heading-color3);
  }
  &-data{
    p{
      margin-bottom: 15px;
      color: (--heading-color3);
      &:last-child{
        margin-bottom: 0;
      }
      span{
        font-size: 21px;
        font-weight: 600;
      }
    }
  }
}

a.aximo-service-icon{
  transition: all 0.5s;
  &:hover{
    margin-left: 7px;
  }
}




