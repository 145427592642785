.aximo-counter{
  &-wrap{
    padding: 30px;
    border-radius: 25px;
    text-align: center;
    background-color: var(--dark-bg);
    @include max(991px) {
      margin-top: 30px;
    }
  }
  &-data{
    padding-bottom: 24px;
    margin-bottom: 20px;
    border-bottom: 1px solid #333;
    &:last-child{
      padding: 0;
      margin: 0;
      border: none;    }
    h2{
      color: var(--accent-color);
      margin-bottom: 5px;
    }
    p{
      font-weight: 600;
      color: var(--white-smoke);
    }
  }
}

.aximo-counter{
  &-wrap2{
    display: flex;
    margin-top: 50px;
    justify-content: space-between;
    @include max(767px) {
      margin-top: 30px;
      display: block;
      text-align: center;
    }
   
  }
  &-data2{
    @include max(991px) {
      padding: 0 20px;
    }
    @include max(767px) {
      padding: 0;
      margin-bottom: 20px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    h2{
      font-weight: 600;
      font-size: 80px;
      margin-bottom: 5px;
      color: var(--heading-color2);
      font-family: $heading-font2;
      @include max(1199px) { 
        font-size: 60px;
      }
      @include max(767px) { 
        font-size: 40px;
        font-weight: 600;
      }
    }
  }
}

.aximo-counter-title{
  text-align: center;
  margin-bottom: 40px;
  p{
    font-size: 21px;
    font-weight: 600;
    color: var(--light-color2);
  }
}

.aximo-counter{
  &-section{
    padding: 80px 0;
    @include max(991px) {
      padding: 80px 0 56px;
    }
  }
  &-wrap3{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include max(575px) {
      display: block;
    }
  }
  &-data3{
    @include max(991px) {
      flex: 0 0 50%;
      text-align: center;
      margin-bottom: 24px;
    }
    h2{
      font-size: 85px;
      margin-bottom: 5px;
      text-transform: uppercase;
      color: var(--heading-color);
      font-family: $heading-font3;
      @include max(1199px) { 
        font-size: 60px;
      }
      @include max(767px) { 
        font-size: 48px;
        font-weight: 600;
      }
      em{
        top: -17px;
        left: -10px;
        position: relative;
        font-style: normal;
      }
    }
    &:nth-child(1) h2{
      color: #8D7DFF;
    }
    &:nth-child(2) h2{
      color: #FF7545;
    }
    &:nth-child(3) h2{
      color: #FF94FF;
    }
    &:nth-child(4) h2{
      color: #FFE757;
    }
    p{
      color: var(--light-color2);
    }
  }
}

.aximo-counter{
  &-wrap4{
    border-radius: 10px;
    padding: 100px 25px;
    margin-bottom: 24px;
    background-color: var(--orange-bg2);
    @include max(767px) {
      padding: 70px;
    }
  }
  &-data4{
    text-align: center;
    h2{
      font-size: 120px;
      margin-bottom: 20px;
      text-align: center;
      color: var(--light-color3);
      font-family: $heading-font4;
      @include max(1399px) { 
        font-size: 100px;
      }
      @include max(1199px) { 
        font-size: 80px;
      }
      @include max(991px) { 
        font-size: 60px;
        margin-bottom: 10px;
      }
      @include max(767px) { 
        font-size: 48px;
      }
    }

    p{
      font-size: 24px;
      font-weight: 600;
      color: var(--light-color3);
      @include max(991px) { 
        font-size: 18px;
      }
    }
  }
}


.aximo-counter{
  &-section2{
    padding: 80px 0 106px;
    @include max(991px) {
      padding: 80px 0 76px;
    }
    @include max(767px) {
      padding: 60px 0 56px;
    }
  }
  &-wrap5{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    @include max(479px) {
      display: block;
    }
  }
  &-data5{
    margin-bottom: 24px;
    position: relative;
    @include max(991px) {
      flex: 0 0 50%;
      text-align: center;
    }
    h2{
      margin-bottom: 10px;
      color: var(--green-color);
      font-family: $heading-font5;
    }

  }
}